import { Col, Row } from "antd"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import React from "react"
import ContactUs from "../../components/contactus"
import FAQ from "../../components/faq"
import HomePage from "../../components/homepage"
import useBreakpoint from "../../utils/useBreakpoint"

import "../../styles/overview.sass"

const styles = {
  imageWrapperStyle: { position: "initial" },
  analyticsDashboardPreviewLeftStyles: {
    left: "-8%",
    top: "43%",
    objectFit: "contain",
    transform: "scale(1.7)",
  },
  analyticsDashboardPreviewRightStyles: {
    left: "28.5%",
    top: "-47%",
    objectFit: "contain",
    transform: "scale(1.5)",
  },
  // First section images
  analyticsDashboardTankReportsGridStyles: {
    filter: "brightness(40%)",
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "16.5%",
    top: "-15.5%",
    objectFit: "contain",
    transform: "scale(2)",
  },
  analyticsDashboardTankReportsViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "5%",
    top: "-20%",
    objectFit: "contain",
    height: 'initial',

    // transform: "scale(1.8)",
  },
  // Second section images.
  // analyticsNeighborTanksGridStyles: {
  //   transition: "transform 0.3s ease-in-out",
  //   willChange: "transform",
  //   left: 0,
  //   top: 0,
  //   objectFit: "contain",
  //   transform: "scale(3.25)",
  // },
  magnusMonitorImagePreviewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    // left: "1.5%",
    top: "20%",
    objectFit: "contain",
    transform: "scale(1.1)",
  },
  appOverviewPreview: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    // left: "1.5%",
    // top: "20%",
    // objectFit: "contain",
    // transform: "scale(1.1)",
  },
  // Third section images.
  analyticsMarketingTanksGridStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "-20.5%",
    top: "22.5%",
    objectFit: "contain",
    transform: "scale(3.175)",
  },
  analyticsMarketingViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "2%",
    top: "9%",
    objectFit: "contain",
    transform: "scale(1.6)",
  },
}

const IndexPage = () => {
  const { breakpoint, isSmallScreen: reverseOrder } = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      analyticsDashboardTankReportsGrid: file(
        relativePath: { eq: "magnus-analytics-tank-reports-grid.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      analyticsDashboardTankReportsView: file(
        relativePath: { eq: "magnus-analytics-tank-report-view.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deviceImagePreview: file(
        relativePath: { eq: "device-image.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appPreview: file(
        relativePath: { eq: "magnus-overview-app-preview.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HomePage>
      <div className="magnus-overview-container">
          <section className="magnus-overview-intro-text">
            <article>
              MAGNUS, the world’s first truly wireless, radar-based level
              monitoring solution. Our unique, advanced fully managed service
              allows you to be proactive with your clients generating better
              customer service levels and loyalty, more than 30% increase in
              logistical efficiency and a quicker ROI.
            </article>
          </section>
        <section className="magnus-platform-info">
          <Row gutter={[20,20]} justify="space-between">
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <div className="magnus-platform-info-title">
                  <h1>magnus platform</h1>
                </div>
                <div className="magnus-platform-info-text">
                  <p className="text-content">
                    Our easy to use dashboard allows you to use any
                    Internet connected device to monitor all of your customer’s
                    containers. This provides the visibility and data required
                    to make more informed decisions which lead to greater
                    efficiency.
                  </p>
                  <Link className="know-more" to="/solutions/magnus-platform">Find out more</Link>
                </div>
            </Col>
            <Col xl={15} lg={15} md={24} sm={24} xs={24}>
                <div className="magnus-platform-info-preview">
                <Img
                    className="magnus-platform-info-preview-grid"
                    imgStyle={styles.analyticsDashboardTankReportsGridStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsGrid.childImageSharp
                        .fluid
                    }
                    alt="magnus-platform-info-grid-preview"
                  />
                  <Img
                    className="magnus-platform-info-preview-view"
                    imgStyle={styles.analyticsDashboardTankReportsViewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsView.childImageSharp
                        .fluid
                    }
                    alt="magnus-platform-info-view-preview"
                  />
                </div>
            </Col>
          </Row>
        </section>
        <section className="magnus-monitor-info">
          <Row gutter={50} justify="space-between">
            <Col
              order={reverseOrder ? 2 : 1}
              xl={15}
              lg={15}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-monitor-info-preview">
                  <Img
                    imgStyle={styles.magnusMonitorImagePreviewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.deviceImagePreview.childImageSharp.fluid
                    }
                    alt="magnus-monitor-info-view-preview"
                  />
                </div>
            </Col>
            <Col
              order={reverseOrder ? 1 : 2}
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-monitor-info-title">
                  <h1>magnus monitor</h1>
                </div>
                <div className="magnus-monitor-info-text">
                  <p className="text-content">
                    MAGNUS is an incredibly high accuracy level monitor that can
                    remotely monitor tanks up to 4m in height. The battery-driven
                    solution is low powered when compared to expensive mains wired
                    solutions and can measure at a high frequency for 5 years.
                    MAGNUS, unlike ultrasonic based solutions, is unaffected by
                    environmental factors such as temperature, dust or humidity.
                  </p>
                  <Link className="know-more" to="/solutions/magnus-monitor">Find out more</Link>
                </div>
            </Col>
          </Row>
        </section>
        <section className="magnus-application-info">
          <Row gutter={50} justify="space-between">
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <div className="magnus-application-info-title">
                  <h1>magnus app</h1>
                </div>
                <div className="magnus-application-info-text">
                  <p className="text-content">
                    Your customers get their monitored level data at their
                    fingertips from anywhere and at any time. MAGNUS analyses data
                    to provide benchmarking against the typical user like them and
                    allows customers to easily review their historical usage data.
                  </p>
                  <Link className="know-more" to="/solutions/magnus-app">Find out more</Link>
                </div>
            </Col>
            <Col xl={15} lg={15} md={24} sm={24} xs={24}>
                <div className="magnus-application-info-preview">
                  <Img
                    className="magnus-application-info-preview-view"
                    imgStyle={styles.appOverviewPreview}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.appPreview.childImageSharp.fluid
                    }
                    alt="magnus-application-info-view-preview"
                  />
                </div>
            </Col>
          </Row>
        </section>
      </div>
      <ContactUs />
    </HomePage>
  )
}

export default IndexPage
